import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import {useAppHistory} from '@/common/hooks/useRouter'
import DividerLine from '@/pages/property/component/DividerLine'
import {Breadcrumb} from '@/components/Breadcrumb'
import {downloadBlob, getImg, timeInterval} from '@/utils/util'
import moment from 'moment'
import {Album} from '@/components/Album'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import {sysModel} from '@/store/models/Sys'

export default function ServiceCancelApply() {
  const history = useAppHistory()
  const cancelAuditStatusArr = useCodeArraySync('steward.order.cancelAudit.status')
  const cancelAuditStatusCode = useCodeSync('steward.order.cancelAudit.status')
  const cancelAuditResultCode = useCodeSync('steward.order.cancelAudit.result')

  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [auditList, setAuditList] = useState<StewardOrderCancelAuditVO[]>([])
  const [checkedAuditList, setCheckedAuditList] = useState<StewardOrderCancelAuditVO[]>([])

  const [visible, setVisible] = useState(false)
  const [auditDetail, setAuditDetail] = useState({} as StewardOrderCancelAuditVO)
  const [checkForm] = Form.useForm()

  const queryCancelAuditList = useCallback(() => {
    const {insertTime, ...formValues} = form.getFieldsValue(true)
    const [insertTimeBegin, insertTimeEnd] = insertTime
    stewardModel
      .stewardOrderCancelAuditList({...formValues, insertTimeEnd, insertTimeBegin})
      .then(({response: {data, total}}) => {
        setTotal(total)
        setAuditList(data)
      })
  }, [form])

  useEffect(() => {
    queryCancelAuditList()
  }, [queryCancelAuditList])

  const queryCancelAuditDetail = useCallback(auditId => {
    stewardModel
      .stewardOrderCancelAuditList({
        auditId,
        pageNum: 1,
        pageSize: 10,
        pageable: false,
      } as StewardOrderCancelAuditQueryDTO)
      .then(({response: {data}}) => {
        if (data.length) setAuditDetail(data[0])
      })
  }, [])

  function onAuditHandle(auditIds: string | string[]) {
    Modal.confirm({
      title: '审核',
      icon: null,
      centered: true,
      closable: true,
      content: (
        <Form
          form={checkForm}
          onFinish={async () => {
            const formValues = checkForm.getFieldsValue(true)
            if (typeof auditIds === 'string') {
              await stewardModel.stewardOrderCancelAuditHandle({...formValues, auditId: auditIds})
              queryCancelAuditDetail(auditIds)
            } else {
              for (let i = 0; i < auditIds.length; i++) {
                await stewardModel.stewardOrderCancelAuditHandle({...formValues, auditId: auditIds[i]})
              }
              setCheckedAuditList([])
            }
            message.success('处理成功！')
            // 刷新列表
            form.setFieldsValue({pageNum: 1})
            queryCancelAuditList()
          }}
          initialValues={{auditResultStatus: '1', auditTag1: '1', auditTag2: '1'}}
        >
          <Form.Item label={'审核结果'} name={'auditResultStatus'}>
            <Radio.Group>
              <Radio value={'1'}>通过</Radio>
              <Radio value={'2'}>驳回</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={'取消原因是否一致'} name={'auditTag1'}>
            <Radio.Group>
              <Radio value={'1'}>是</Radio>
              <Radio value={'0'}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={'管家是否建议过用户自行取消'} name={'auditTag2'}>
            <Radio.Group>
              <Radio value={'1'}>是</Radio>
              <Radio value={'0'}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={'审核备注'} name={'auditorRemark'} rules={[{required: true, message: '请输入审核备注'}]}>
            <Input.TextArea allowClear maxLength={200} placeholder={'请输入审核备注'} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await checkForm.validateFields()
        checkForm.submit()
      },
      afterClose: () => checkForm.resetFields(),
    })
  }

  const listLoading = stewardModel.stewardOrderCancelAuditList.useLoading()

  const [sysCity, setSysCity] = useState<Option[]>([])
  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      setSysCity(data?.city)
    })
  }, [])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              <Button
                onClick={() => {
                  if (checkedAuditList.length === 0) return message.warning('请选择待处理的申请!')
                  onAuditHandle(checkedAuditList.map(item => item.auditId))
                }}
              >
                审核
              </Button>
              <Button
                onClick={async () => {
                  await form.validateFields()
                  const {insertTime, ...value} = form.getFieldsValue(true)
                  const [insertTimeBegin, insertTimeEnd] = insertTime
                  downloadBlob({
                    url: '/steward/order/cancel-audit/list-excel',
                    body: {...value, pageable: false, insertTimeBegin, insertTimeEnd},
                  })
                }}
              >
                导出
              </Button>
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{span: '80px'}}
          labelAlign={'left'}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            insertTime: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
          }}
          onFinish={queryCancelAuditList}
          onReset={queryCancelAuditList}
        >
          <Row gutter={40}>
            <Col span={8}>
              <Form.Item name={'cityCode'} label={'城市'}>
                <Select
                  placeholder={'请选择'}
                  allowClear
                  fieldNames={{label: 'label', value: 'id'}}
                  options={sysCity}
                  showSearch
                  filterOption={(inputValue, option) => option.label?.includes(inputValue)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务单ID'} name={'orderId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务联系手机号'} name={'contactMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'申请人'} name={'serverName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'申请状态'} name={'auditStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  {cancelAuditStatusArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'申请时间'}
                name={'insertTime'}
                getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                getValueFromEvent={(_, dateStrings) => {
                  const [insertTimeBegin, insertTimeEnd] = dateStrings
                  if (insertTimeBegin && insertTimeEnd) {
                    return [
                      moment(insertTimeBegin).format(FORMAT_DATE_START),
                      moment(insertTimeEnd).format(FORMAT_DATE_END),
                    ]
                  }
                  return undefined
                }}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value) {
                        const [insertTimeBegin, insertTimeEnd] = value
                        const intervalDay = moment(insertTimeEnd).diff(insertTimeBegin, 'day')
                        if (intervalDay > 31) return callback('申请时间间隔不超过31天')
                      } else {
                        return callback('申请时间不能为空')
                      }
                      return callback()
                    },
                  },
                ]}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'auditId'}
          loading={listLoading}
          dataSource={auditList}
          rowSelection={{
            getCheckboxProps: record => ({disabled: record.auditStatus !== '1'}),
            selectedRowKeys: checkedAuditList.map(item => item.auditId),
            onChange: (_, selectedRows) => setCheckedAuditList(selectedRows),
          }}
          columns={[
            {
              title: '服务单ID',
              dataIndex: 'orderId',
              render: value => (
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => history.push('/steward/detail', {orderId: value})}
                >
                  <span style={{textDecoration: 'underline', color: 'rgba(0, 0, 0, 0.65)'}}>{value}</span>
                </Button>
              ),
            },
            {title: '申请人', dataIndex: 'serverName', render: (value, record) => `${value}（${record.serverMobile}）`},
            {
              title: '申请时间',
              dataIndex: 'insertTime',
              render: (value, record) => (
                <>
                  <div>{value}</div>
                  {!!record.guanyuExpireAt && <div style={{color: '#f00'}}>{timeInterval(record.guanyuExpireAt)}</div>}
                </>
              ),
            },
            {title: '下单人', dataIndex: 'orderMakerName'},
            {title: '服务联系手机号', dataIndex: 'contactMobile'},
            {title: '服务项目', dataIndex: 'categoryName'},
            {title: '房源', dataIndex: 'propertyAddr'},
            {title: '申请状态', dataIndex: 'auditStatus', render: value => cancelAuditStatusCode[value]},
            {
              title: '操作',
              fixed: 'right',
              render: record => (
                <Space>
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={async () => {
                      setVisible(true)
                      setAuditDetail(record)
                    }}
                  >
                    查看
                  </Button>
                </Space>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Drawer
        visible={visible}
        width={700}
        title={'服务详情'}
        onClose={() => setVisible(false)}
        // afterVisibleChange={visible => {
        //   console.log('visible', visible)
        // }}
      >
        <Descriptions
          title={
            <DividerLine
              title={'基本信息'}
              right={
                auditDetail.auditStatus === '1' && (
                  <Button
                    type={'link'}
                    onClick={() => {
                      onAuditHandle(auditDetail.auditId)
                    }}
                  >
                    审核
                  </Button>
                )
              }
            />
          }
          column={2}
        >
          <Descriptions.Item label={'服务单ID'}>{auditDetail.orderId}</Descriptions.Item>
          <Descriptions.Item label={'下单人'}>{auditDetail.orderMakerName}</Descriptions.Item>
          <Descriptions.Item label={'下单时间'}>{auditDetail.timeMake}</Descriptions.Item>
          <Descriptions.Item label={'服务联系手机号'}>{auditDetail.contactMobile}</Descriptions.Item>
          <Descriptions.Item
            label={'申请人'}
          >{`${auditDetail.serverName}（${auditDetail.serverMobile}）`}</Descriptions.Item>
          <Descriptions.Item label={'申请时间'}>{auditDetail.insertTime}</Descriptions.Item>
          <Descriptions.Item label={'服务项目'}>{auditDetail.categoryName}</Descriptions.Item>
          <Descriptions.Item label={'房源'}>{auditDetail.propertyAddr}</Descriptions.Item>
          <Descriptions.Item label={'取消原因'}>{auditDetail.cancelType}</Descriptions.Item>
          <Descriptions.Item label={'具体描述'}>{auditDetail.cancelReason}</Descriptions.Item>
          {auditDetail.auditStatus === '4' && (
            <>
              <Descriptions.Item label={'撤回描述'}>{auditDetail.rollbackReason}</Descriptions.Item>
              <Descriptions.Item label={'撤回时间'}>{auditDetail.rollbackTime}</Descriptions.Item>
            </>
          )}
        </Descriptions>
        <Descriptions>
          <Descriptions.Item label={'照片'}>
            {auditDetail.imgUrlList?.length ? (
              <Album fileList={auditDetail.imgUrlList.map(item => ({url: getImg(item), uid: item}))} disabled>
                {null}
              </Album>
            ) : (
              '无'
            )}
          </Descriptions.Item>
        </Descriptions>

        {['2', '3'].includes(auditDetail.auditStatus) && (
          <Descriptions title={<DividerLine title={'审核信息'} />} column={2} style={{marginTop: '30px'}}>
            <Descriptions.Item label={'审核结果'}>
              {cancelAuditResultCode[auditDetail.auditResultStatus]}
            </Descriptions.Item>
            <Descriptions.Item label={'审核人'}>{auditDetail.auditorName}</Descriptions.Item>
            <Descriptions.Item label={'审核时间'}>{auditDetail.auditTime}</Descriptions.Item>
            <Descriptions.Item label={'取消原因是否一致'}>
              {auditDetail.auditTag1 === 1 ? '是' : '否'}
            </Descriptions.Item>
            <Descriptions.Item label={'管家是否建议过用户自行取消'}>
              {auditDetail.auditTag2 === 1 ? '是' : '否'}
            </Descriptions.Item>
            <Descriptions.Item label={'审核备注'}>{auditDetail.auditorRemark}</Descriptions.Item>
          </Descriptions>
        )}
      </Drawer>
    </Page>
  )
}
